import React from "react";
import { Link } from "gatsby"
import LayoutFullPDF from "../../components/layoutFullPDF";
import { graphql } from 'gatsby'
import GCColumnPerformanceSummary from "../../components/organisms/pdfs/gCColumnPerformanceSummary";
import styled from 'styled-components'
import LogoImage from '../../components/atoms/logoImage'
import Seo from "../../components/atoms/seo";

const SiteTitle = styled.h1`
    margin-top: 20px;
    margin-left: 20px;
    font-size: 35px;
    font-weight: bold;
    @media screen and (max-width: 767px) {
      margin-top: 5px;
      margin-bottom: 0;
    }
    float: left;
`
const StyledLink = styled(Link)`
  color: #414141;
  text-decoration: none;
  &:hover{
    color: #414141;
  }
`
const ImageContainer = styled.span`
   width: 160px;
   padding: 8px 45px 8px 15px;
   margin: 5px;
   border-right: 1px solid #858585;
   float: left;
`
const Clear = styled.div`
    clear: both;

`

export default class GCColumnPerformanceSummaryPage extends React.Component {
  render() {
    return (
      <LayoutFullPDF>
           <Seo pdf={true} title="PDF" keywords={[`PerkinElmer`]} />
           <ImageContainer style={{width: "190px"}}>
          <Link to="/"><LogoImage></LogoImage></Link>
        </ImageContainer>
        <SiteTitle><StyledLink to="/">{"Elite Series® GC Capillary Column"}</StyledLink></SiteTitle>
        <Clear></Clear>
        <GCColumnPerformanceSummary></GCColumnPerformanceSummary>
      </LayoutFullPDF>
    );
  }
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "coa"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
