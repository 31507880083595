import React from "react"
import PropTypes from "prop-types"
import styled from 'styled-components'
import "./layout.css"
import 'core-js/modules/es.array.find';

const Content = styled.div`
    max-width: 1200px;
    width: 100%;
    padding: 1.45rem 1.0875rem;
`

const LayoutFullPDF = ({ ...props }) => (
    <Content style={{margin: props.layout === "center" ? "auto" : ""}}>{props.children}</Content>
)

LayoutFullPDF.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutFullPDF
